@import 'variables';
@import '~bootstrap/scss/bootstrap';

body {
  max-width: 900px; 
  margin: auto;
  font-size: 0.8em;
}

.gold {               // Hintergrund gold
  color: $secondary;
  background-color: $primary;
}

.blau {               // Hintergrund blau
  color: $primary;
  background-color: $secondary;
}

.btn {
  margin: 5px;
  padding: 0;
  height: 35px;
}

.input {
  margin: 0.1em;
  padding:  0;
}

.rund { border-radius: 10em; }

.zentriert {
  display: flex;
  justify-content: center;
  align-items: center;
//  text-align: center;  // horizontal
//  vertical-align: middle;    // vertikal
}

.zenthori {
  text-align: center;  // horizontal
}

.zentvert {
  vertical-align: middle;    // vertikal
}

//=====================

pre, .pre, .debug {
  color: yellow;
  background-color: black;
  margin: 0.5em;
  padding: 0.5em;
  border: red 2px dashed;
}
