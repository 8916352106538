
$primary: rgb(192, 160, 98);     // WMV-Gold
$secondary: rgb(0, 51, 102);     // WMV-Blau
$black: #000000;
$white: #FFFFFF;
$body-bg: $secondary;
$body-color: $primary;
$table-hover-bg: rgba($white, 0.30);
$table-accent-bg: rgba($white, 0.15);
$table-border-color: $primary;
$input-bg: $white;
$input-color: $black;
